import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class IconList extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const componentStyles = {};

    return (
      <ul
        className="component icon-list"
        id={this.props.id}
        style={componentStyles}
      >
        {this.props.children}
      </ul>
    );
  }
}

export default IconList;
