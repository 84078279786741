import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Icon extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageHeight: PropTypes.number.isRequired,
    imageWidth: PropTypes.number.isRequired,
    description: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { title, image, url, imageHeight, imageWidth, description } = this.props;

    return (
      <div className="component icon">
        <div className="image-wrapper">
          <img src={image} alt="" height={imageHeight} width={imageWidth} />
        </div>
        {!url && <h3 className="title">{title}</h3>}
        {url && <a href={url} title={title} alt={title}><h3 className="title">{title}</h3></a>}
        <p className="description">{description}</p>
      </div>
    );
  }
}

export default Icon;
