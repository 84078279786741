import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Banner from '../../../core/Banner';
import Panel from '../../../core/Panel';
import Heading from '../../../core/Heading';
import Columns from '../../../core/Columns';
import Column from '../../../core/Column';
import FeatureList from '../../../core/FeatureList';
import Image from '../../../core/Image';
import IconList from '../../../core/IconList';
import Icon from '../../../core/Icon';
import Paragraph from '../../../core/Paragraph';

import './index.css';

import reservationCalendarImage from './images/Calendar_comp.svg';
import reportsImage from './images/Reports_comp.svg';
import pciCompliantImage from './images/pci.svg';
import onTheCloudImage from './images/cloud.svg';
import supportImage from './images/support.svg';
import supplyAndDemandImage from './images/supply-demand.svg';

import automaticEmailsImage from './images/icons/automatic_emails.svg';
import giftCertificatesImage from './images/icons/gift_certificates.svg';
import guestManagementImage from './images/icons/guest_management.svg';
import housekeepingImage from './images/icons/housekeeping.svg';
import minimumNightsImage from './images/icons/minimum_nights.svg';
import multipleRatesImage from './images/icons/multiple_rates.svg';
import taskManagementImage from './images/icons/task_management.svg';
import waitListImage from './images/icons/wait_list.svg';

import bookingengineListImage from './images/icons/glyph_booking_engine.svg';
import channelmanagerListImage from './images/icons/glyph_channel_mgr.svg';
import paymentprocessingListImage from './images/icons/glyph-payment-processing.svg';
import integrationsListImage from './images/icons/glyph-integrations.svg';

export class PropertyManagementSystemPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component property-management-system-page">
          <Helmet title="Hotel Property Management System – ThinkReservations">
            <meta
              name="description"
              content="Simplify your business operations with our PCI-compliant hotel property management system. We offer 24/7 support. Learn more here."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Service',
                serviceType: 'Property Management System',
                category:
                  'Reservation Management Software for Small and Boutique Hotels',
                provider: {
                  '@type': 'Organization',
                  name: 'ThinkReservations',
                },
                areaServed: {
                  '@type': 'Country',
                  name: 'USA',
                },
              })}
            />
          </Helmet>

          <Banner
            header="Property Management System"
            subheader="Simple and professional"
            content="Our hotel property management system is specially designed to simplify the way you run your business operations - empowering your staff to be more productive and provide better service. Manage a wide range of tasks all in one cloud-based place through our secure and multifaceted hospitality management system. From reservations to accounting, taxes to housekeeping, it’s all accessible with this tool. The features and functionality of our hotel management software will allow your business to increase revenue and save time."
          />

          <Panel id="reservation-calendar">
            <Heading size="large" level={2}>
              A reservation calendar you can depend on
            </Heading>
            <Columns verticalAlign="top">
              <Column>
                <Image src={reservationCalendarImage} />
              </Column>
              <Column>
                <FeatureList>
                  <li>View reservations at a glance</li>
                  <li>Quickly quote a guest</li>
                  <li>Drag and drop reservations</li>
                  <li>Colors and icons to indicate reservation details</li>
                </FeatureList>
              </Column>
            </Columns>
          </Panel>

          <Panel id="more-features" backgroundColor="#f5f3f3">
            <Columns>
              <Column>
                <Icon
                  title="PCI Compliant"
                  image={pciCompliantImage}
                  imageWidth={188}
                  imageHeight={92}
                  description="We employ the highest security standards"
                />
              </Column>
              <Column>
                <Icon
                  title="On the cloud"
                  image={onTheCloudImage}
                  imageWidth={135}
                  imageHeight={95}
                  description="Access your data anywhere with this hospitality property management software"
                />
              </Column>
              <Column>
                <Icon
                  title="24/7 support"
                  image={supportImage}
                  imageWidth={105}
                  imageHeight={105}
                  description="We're here to help you via phone or email"
                />
              </Column>
            </Columns>
          </Panel>

          <Panel id="reports">
            <Columns>
              <Column colSpan={4}>
                <Heading size="large" level={2}>
                  Full report suite
                </Heading>
                <FeatureList>
                  <li>View reservation statistics</li>
                  <li>Analyze booking pace</li>
                  <li>Simplify accounting</li>
                  <li>Calculate taxes</li>
                  <li>Reconcile payments</li>
                  <li>Export reports to Excel</li>
                </FeatureList>
              </Column>
              <Column colSpan={6}>
                <Image src={reportsImage} />
              </Column>
            </Columns>
          </Panel>

          <Panel id="yield-management" backgroundColor="#575451">
            <Heading size="large" level={2}>
              Yield management
            </Heading>
            <Columns>
              <Column colSpan={6.25}>
                <Image src={supplyAndDemandImage} />
              </Column>
              <Column id="panel-dynamic-pricing-column-1" colSpan={3.75}>
                <div id="panel-dynamic-pricing-column-1-inner-wrapper">
                  <FeatureList bulletColor="white">
                    <li>
                      <div className="title">
                        Change price based on occupancy and booking window
                      </div>
                    </li>
                    <li>
                      <div className="title">
                        Generate reports to predict demand
                      </div>
                    </li>
                    <li>
                      <div className="title">Quickly make rate adjustments</div>
                    </li>
                    <li>
                      <div className="title">
                        Offer multiple rates with varying deposit or
                        cancellation policies
                      </div>
                    </li>
                  </FeatureList>
                </div>
              </Column>
            </Columns>
          </Panel>

          <Panel id="panel-support-rates" backgroundColor="#f5f3f3">
            <IconList>
              <li>
                <Icon
                  title="Guest Management"
                  image={guestManagementImage}
                  imageWidth={135}
                  imageHeight={112}
                />
              </li>
              <li>
                <Icon
                  title="Housekeeping"
                  image={housekeepingImage}
                  imageWidth={101}
                  imageHeight={108}
                />
              </li>
              <li>
                <Icon
                  title="Gift Certificates"
                  image={giftCertificatesImage}
                  imageWidth={117}
                  imageHeight={104}
                />
              </li>
              <li>
                <Icon
                  title="Wait List"
                  image={waitListImage}
                  imageWidth={108}
                  imageHeight={112}
                />
              </li>
              <li>
                <Icon
                  title="Task Management"
                  image={taskManagementImage}
                  imageWidth={81}
                  imageHeight={108}
                />
              </li>
              <li>
                <Icon
                  title="Automatic Emails"
                  image={automaticEmailsImage}
                  imageWidth={94}
                  imageHeight={94}
                />
              </li>
              <li>
                <Icon
                  title="Minimum Night Adjustments"
                  image={minimumNightsImage}
                  imageWidth={104}
                  imageHeight={104}
                />
              </li>
              <li>
                <Icon
                  title="Multiple Rates"
                  image={multipleRatesImage}
                  imageWidth={109}
                  imageHeight={92}
                />
              </li>
            </IconList>
          </Panel>

          <Panel id="more-features">
            <Heading size="large" level={2}>
              Even more available features
            </Heading>
            <Paragraph>
              In addition to our top-tier hotel property management software, we
              also provide additional products and features to further your
              success in the hospitality business. Our offerings include:
            </Paragraph>
            <Columns verticalAlign="top">
              <Column>
                <IconList>
                  <li>
                    <Icon
                      title="Booking engine"
                      url="/products/booking-engine"
                      image={bookingengineListImage}
                      imageWidth={135}
                      imageHeight={112}
                    />
                  </li>
                  <li>
                    <Icon
                      title="Channel manager"
                      url="/products/channel-manager"
                      image={channelmanagerListImage}
                      imageWidth={101}
                      imageHeight={108}
                    />
                  </li>
                  <li>
                    <Icon
                      title="Payment processing"
                      url="/products/payment-processing"
                      image={paymentprocessingListImage}
                      imageWidth={117}
                      imageHeight={104}
                    />
                  </li>
                  <li>
                    <Icon
                      title="Integrations"
                      url="/products/integrations"
                      image={integrationsListImage}
                      imageWidth={108}
                      imageHeight={112}
                    />
                  </li>
                </IconList>
              </Column>
            </Columns>
          </Panel>
        </div>
      </App>
    );
  }
}

export default PropertyManagementSystemPage;
